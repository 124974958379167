import React from 'react';

const Unfooter = () => {
  return (
    <div className="footer-links">
      <a href="/mentions-legales">Mentions légales</a>
      <a href="/cgv">CGV</a>
      <a href="/donnees-personnelles">Données personnelles</a>
      <a href="/cookie">Cookie</a>
    </div>
  );
};

export default Unfooter;
