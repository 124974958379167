import React from 'react';
import ContactForm from './ContactForm';

const ContactUs = () => {
  return (
     <div className="container mt-5 contact-us">
      <h2><strong>Nous Contacter</strong></h2>
      <div className="row">
        <div className="col-md-6 contact-form-us">
          <ContactForm />
        </div>
        <div className="col-md-6">
          <div className="container">
          <img src="/static/contactus.jpg" alt="contactez-nous agn diagnostics" className="img-fluid shadow rounded" />
          <h4><strong>Qui contacter ?</strong></h4>
          <p className='shadow rounded'>
            <strong>Diagnostics & Audits:</strong> Romain NERON
            <br />
            <strong>Téléphone:</strong> 06 29 75 43 49
            <br />
            <strong>Email:</strong> agndiagnostics@hotmail.fr
          </p>
          <p className='shadow rounded'>
            <strong>Diagnostics:</strong> Hyacinthe THOMAS
            <br />
            <strong>Téléphone:</strong> 06 19 16 14 50
            <br />
            <strong>Email:</strong> agndiagnostics@hotmail.com
          </p>
          <p className='shadow rounded'>
            <strong>Diagnostics:</strong> Sarah LAURENT
            <br />
            <strong>Téléphone:</strong> 06 65 26 24 48
            <br />
            <strong>Email:</strong> agndiagnostics@gmail.com
          </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;