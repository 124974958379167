import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import DiagnosticTypes from './components/DiagnosticsTypes';
import ContactUs from './components/ContactUs';
import MentionsLegales from './components/extra/MentionsLegales';
import CGV from './components/extra/CGV';
import DonneesPersonnelles from './components/extra/DonneesPersonnelles';
import Cookie from './components/extra/Cookie';
import Footer from './components/Footer';
import Unfooter from './components/Unfooter';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/nos-prestations" element={<DiagnosticTypes />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/mentions-legales" element={<MentionsLegales />} />
            <Route path="/cgv" element={<CGV />} />
            <Route path="/donnees-personnelles" element={<DonneesPersonnelles />} />
            <Route path="/cookie" element={<Cookie />} />
          </Routes>
        </main>
        <Footer />
        <Unfooter />
      </div>
    </Router>
  );
}

export default App;