import React from 'react';
import CompanyPresentation from './CompanyPresentation';
import Maps from './Maps';
import Engagement from './Engagement';
import CarouselHome from './Carousel';
import CenterBackground from './CenterBackground';
import TeamMembers from './TeamMembers';

const Home = () => {
  return (
    <div>
        <CarouselHome />
        <CompanyPresentation />
        <div class="container-bg-dpe">
          <Maps />
          <CenterBackground />
          <Engagement />
        </div>
        <TeamMembers />
    </div>
  );
};

export default Home;