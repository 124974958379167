import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src="/static/logo-agn-diagnostics-vichy-allier.png"
              alt="AGN Diagnostics Logo Vichy Allier"
              className="logo"
            />
           <span>AGN Diagnostics</span>
          </Link>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/nos-prestations">
                NOS PRESTATIONS
                <img src="/static/agn_line.png" alt="agndiagnostics" className="agnline" />
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact-us">
                NOUS CONTACTER
                <img src="/static/agn_line.png" alt="agndiagnostics" className="agnline" />
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;