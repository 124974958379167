import React from 'react';

const ContactUs = () => {
  return (
    <div className="container mt-5 text-start">
      <h2>Politique de sécurité des données</h2><br /><br />

      <h3><strong>1. Objectif</strong></h3>
      <p>
        L’entreprise doit restreindre l’accès aux données confidentielles et sensibles pour éviter qu’elles ne soient perdues ou compromises, de façon à ne pas nuire à nos clients, à ne pas encourir de sanctions pour non-conformité et à ne pas nuire à notre réputation.<br />
        Il n’est pas attendu de cette politique qu’elle élimine tous les vols de données.<br /> Son principal objectif est plutôt de sensibiliser les utilisateurs et d’éviter les scénarios de perte accidentelle, c’est pourquoi elle décrit les exigences de prévention des fuites de données.<br /><br />
      </p>

      <h3><strong>2. Champ d’application</strong></h3><p>
        <h4>2.1 Dans le champ d’application</h4>
        Cette politique de sécurité des données s’applique à toutes les données clients, données personnelles ou autres données de l’entreprise définies comme sensibles par la politique de classification des données de l’entreprise. <br />
        Elle s’applique donc à tout appareil régulièrement utilisé pour le courrier électronique, l’accès au Web ou d’autres tâches professionnelles. <br />
        Tout utilisateur qui interagit avec les services informatiques de l’entreprise est également soumis à cette politique.
        <h4>2.2 Hors du champ d’application</h4>
        Les informations classées comme publiques ne sont pas soumises à cette politique. <br />D’autres données peuvent être exclues de la politique par la direction de l’entreprise, en fonction d’impératifs spécifiques, par exemple le fait que la protection des données est trop coûteuse ou trop complexe.
        <br /><br />
      </p>

      <h3><strong>3. Politique</strong></h3>
      <p>
        <h4>3.1 Principes</h4>
        L’entreprise fournira à tous ses employés et à ses sous - traitants l’accès aux informations dont ils ont besoin pour faire leur travail aussi efficacement que possible.
        <h4>3.2 Responsabilités des utilisateurs</h4>
        a.Tous les employés doivent verrouiller leur écran chaque fois qu’ils quittent leur bureau, pour réduire le risque d’accès non autorisé.<br />
        b.Tous les employés doivent veiller à ne laisser aucune information sensible ou confidentielle autour de leur poste de travail.<br />
        c.Tous les employés doivent tenir leurs mots de passe confidentiels et ne pas les partager.<br />
        <h4>3.3 Accès aux applications et aux informations</h4>
        a.Tous les employés et sous - traitants de l’entreprise doivent bénéficier d’un accès aux données et aux applications nécessaires à leur fonction professionnelle.<br />
        b.Tous les employés et sous - traitants ne doivent accéder aux données et systèmes sensibles qu’en cas de nécessité professionnelle et avec l’accord de la direction.<br />
      </p><br />

      <h3><strong>4. Directives techniques</strong></h3>
      <p>
        Les méthodes de contrôle d’accès à utiliser incluent:<br />
        Audit des tentatives de connexion à tout appareil connecté au réseau de l’entreprise
        Contrôle d'accès de boîte mail (MAC)
      </p><br />

      <h3><strong>5. Propriété et responsabilités</strong></h3>
      <p>
      Les propriétaires de données sont des employés dont la principale responsabilité est de gérer les informations qu’ils possèdent; il peut s’agir par exemple d’un diagnostiqueurs ou du gérant.<br />
      Les utilisateurs comprennent tous ceux qui ont accès aux ressources informatiques, par exemple les employés, les entités de confiance, les sous - traitants, les consultants, les employés à l’essai, les employés temporaires et les bénévoles.<br />
      </p><br />
      
      <h3><strong> 6. Application</strong></h3>
      <p>
      Tout utilisateur qui enfreint cette politique est passible de sanctions disciplinaires, pouvant aller jusqu’au licenciement.<br />
      Tout partenaire ou sous - traitant tiers surpris en infraction peut voir sa connexion au réseau suspendue.
      </p><br />
    </div >
  );
};

export default ContactUs;