import React from 'react';

const ContactUs = () => {
  return (
    <div className="container mt-5 text-start">
      <h2><strong>Cookie</strong></h2>
      <p>
        Un « cookie » est un petit fichier d’information envoyé sur le navigateur de l’Utilisateur et enregistré au sein du terminal de l’Utilisateur (ex : ordinateur, smartphone), (ci-après « Cookies »). Ce fichier comprend des informations telles que le nom de domaine de l’Utilisateur, le fournisseur d’accès Internet de l’Utilisateur, le système d’exploitation de l’Utilisateur, ainsi que la date et l’heure d’accès. Les Cookies ne risquent en aucun cas d’endommager le terminal de l’Utilisateur.
      </p>
      <p>
        <strong>https://www.agndiagnostics.fr</strong> est susceptible de traiter les informations de l’Utilisateur concernant sa visite du Site, telles que les pages consultées, les recherches effectuées. Ces informations permettent à <strong>https://www.agndiagnostics.fr</strong> d’améliorer le contenu du Site, de la navigation de l’Utilisateur.
      </p>
      <p>
        Les Cookies facilitant la navigation et/ou la fourniture des services proposés par le Site, l’Utilisateur peut configurer son navigateur pour qu’il lui permette de décider s’il souhaite ou non les accepter de manière à ce que des Cookies soient enregistrés dans le terminal ou, au contraire, qu’ils soient rejetés, soit systématiquement, soit selon leur émetteur.<br />
        L’Utilisateur peut également configurer son logiciel de navigation de manière à ce que l’acceptation ou le refus des Cookies lui soient proposés ponctuellement, avant qu’un Cookie soit susceptible d’être enregistré dans son terminal.<br />
        <strong>https://www.agndiagnostics.fr</strong> informe l’Utilisateur que, dans ce cas, il se peut que les fonctionnalités de son logiciel de navigation ne soient pas toutes disponibles.
      </p>
      <p>
        Si l’Utilisateur refuse l’enregistrement de Cookies dans son terminal ou son navigateur, ou si l’Utilisateur supprime ceux qui y sont enregistrés, l’Utilisateur est informé que sa navigation et son expérience sur le Site peuvent être limitées.
        Cela pourrait également être le cas lorsque <strong>https://www.agndiagnostics.fr</strong> ou l’un de ses prestataires ne peut pas reconnaître, à des fins de compatibilité technique, le type de navigateur utilisé par le terminal, les paramètres de langue et d’affichage ou le pays depuis lequel le terminal semble connecté à Internet.
      </p>
      <p>
        Le cas échéant, <strong>https://www.agndiagnostics.fr</strong> décline toute responsabilité pour les conséquences liées au fonctionnement dégradé du Site et des services éventuellement proposés par <strong>https://www.gabinrn.fr</strong>, résultant (i) du refus de Cookies par l’Utilisateur (ii) de l’impossibilité pour <strong>https://www.agndiagnostics.fr</strong> d’enregistrer ou de consulter les Cookies nécessaires à leur fonctionnement du fait du choix de l’Utilisateur.<br />
        Pour la gestion des Cookies et des choix de l’Utilisateur, la configuration de chaque navigateur est différente.<br />
        Elle est décrite dans le menu d’aide du navigateur, qui permettra de savoir de quelle manière l’Utilisateur peut modifier ses souhaits en matière de Cookies.
      </p>
      <p>
        À tout moment, l’Utilisateur peut faire le choix d’exprimer et de modifier ses souhaits en matière de Cookies. <strong>https://www.agndiagnostics.fr</strong> pourra en outre faire appel aux services de prestataires externes pour l’aider à recueillir et traiter les informations décrites dans cette section.
      </p>
      <p>
        Enfin, en cliquant sur les icônes dédiées aux réseaux sociaux Twitter, Facebook, Linkedin et Google Plus figurant sur le Site de <strong>https://www.agndiagnostics.fr</strong> ou dans son application mobile et si l’Utilisateur a accepté le dépôt de cookies en poursuivant sa navigation sur le Site Internet ou l’application mobile de <strong>https://www.agndiagnostics.fr</strong>, Twitter, Facebook, Linkedin et Google Plus peuvent également déposer des cookies sur vos terminaux (ordinateur, tablette, téléphone portable).
      </p>
      <p>
        Ces types de cookies ne sont déposés sur vos terminaux qu’à condition que vous y consentiez, en continuant votre navigation sur le Site Internet ou l’application mobile de <strong>https://www.agndiagnostics.fr</strong>. À tout moment, l’Utilisateur peut néanmoins revenir sur son consentement à ce que <strong>https://www.agndiagnostics.fr</strong> dépose ce type de cookies.
      </p>

    </div>
  );
};

export default ContactUs;