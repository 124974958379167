import React from 'react';

const ContactUs = () => {
  return (
    <div className="container mt-5 text-start">
      <h2><strong>Mentions Légales</strong></h2>
      <p>Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l’économie numérique, il est précisé aux utilisateurs du site <strong>https://www.agndiagnostics.fr</strong> l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi. </p>

      <h3>Edition du site</h3>
      <p>Le site <strong>https://www.agndiagnostics.fr</strong> est édité par la société <strong>NERON Gabin EI</strong>, société individuelle dont le siège social est situé 81 avenue de Bellevue à SAINT-YORRE (03270), identifié sous le numéro SIREN 979 293 495 et immatriculé au registre du commerce et des société de CUSSET</p>
      <br />

      <h3>Responsable de modification</h3>
      <p>
        <strong>NERON Gabin</strong><br />
        Email : gabinrn.pro@gmail.com<br />
        Téléphone : 06 04 53 94 72
      </p><br />

      <h3>Hébergeur</h3>
      <p>
        Le site <strong>https://www.agndiagnostics.fr</strong> est hébergé par la société Amazon Web Services LLC<br />
        Adresse: P.O. Box 81226, Seattle, WA 98108-1226
      </p><br />

      <h3>Conditions générales d’utilisation du site et des services proposés.</h3>
      <p>
        Le Site constitue une œuvre de l’esprit protégée par les dispositions du Code de la Propriété Intellectuelle et des Réglementations Internationales applicables.<br />
        Le Client ne peut en aucune manière réutiliser, céder ou exploiter pour son propre compte tout ou partie des éléments ou travaux du Site.
      </p>
      <p>
        L’utilisation du site <strong>https://www.agndiagnostics.fr</strong> implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites.<br />
        Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les utilisateurs du site <strong>https://www.agndiagnostics.fr</strong> sont donc invités à les consulter de manière régulière.
      </p>
      <p>
        Ce site internet est normalement accessible à tout moment aux utilisateurs.<br />
        Une interruption pour raison de maintenance technique peut être toutefois décidée par <strong>https://www.agndiagnostics.fr</strong>, qui s’efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l’intervention.
      </p>
      <p>
        Le site web <strong>https://www.agndiagnostics.fr</strong> est mis à jour régulièrement par la société NERON Gabin EI responsable.<br />
        De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre connaissance.
      </p>
    </div>
  );
};

export default ContactUs;