import React from 'react';

const ContactUs = () => {
  return (
    <div className="container mt-5">
      <h2><strong>Condition générales de ventes</strong></h2>
      En cours de rédaction...
    </div>
  );
};

export default ContactUs;