import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faPhone, faStreetView, faEnvelope, faStar, faTag, faBalanceScale } from '@fortawesome/free-solid-svg-icons';  

const Engagement = () => {
  const engagementItems = [
    {
      title: 'Professionalisme',
      description: 'Des experts certifiés et qualifiés pour répondre à vos besoins',
      icon: faBriefcase,
    },
    {
      title: 'Disponibilité',
      description: 'Une équipe disponible et à votre écoute',
      icon: faPhone,
    },
    {
      title: 'Secteur',
      description: 'Intervention dans toute l\'Allier',
      icon: faStreetView,
    },
    {
      title: 'Réactivité',
      description: 'Des comptes rendus réalisés sous 24h selon dossier',
      icon: faEnvelope,
    },
    {
      title: 'Qualité',
      description: 'Des rapports de qualité, clairs et précis',
      icon: faStar,
    },
    {
      title: 'Tarifs',
      description: 'Des tarifs compétitifs',
      icon: faTag,
    },
    {
      title: 'Comformité',
      description: 'Des diagnostics réalisés selon les normes en vigueur',
      icon: faBalanceScale,
    }
  ];

  return (
    <section className="container mt-5">
      <h2 className="txt-white">Nos engagements</h2>
      <div className="row justify-content-center align-items-stretch">
        {engagementItems.map((item, index) => (
          <div key={index} className="col-lg-3 col-md-4 mb-3">
            <div className="card shadow h-100">
              <div className="card-body text-center">
                <div className="icon mb-2">
                  <FontAwesomeIcon icon={item.icon} size="2x" />
                </div>
                <h5 className="card-title">{item.title}</h5>
                <p className="card-text text-orange">{item.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Engagement;
