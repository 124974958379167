import React from 'react';

const Maps= () => {
  return (
    <div className="map-container mt-5">
      <h2>Où nous trouver ?</h2>
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          title="Localisation de l'entreprise"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2895.689146866662!2d3.396141215542086!3d46.10133049878047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47f6a7596319bf33%3A0x73bc4f5188c101c9!2s2%20Avenue%20de%20Thiers%2C%2003200%20Abrest%2C%20France!5e0!3m2!1sen!2sfr!4v1634276742131!5m2!1sen!2sfr"
          width="50%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          className="centered-map"
        ></iframe>
      </div>
    </div>
  );
};

export default Maps;