import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';  

const Footer = () => {
  return (
    <footer className="footer mt-5 shadow">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h4>Contact</h4>
            <p>
              Adresse: 2 Avenue de Thiers, Abrest 03200, France
              <br />
              Email: contact@agndiagnostics.fr
              <br />
              Téléphone: 06 29 75 43 49
            </p>
          </div>
          <div className="col-md-6">
            <h4 className="mb-4">Suivez-nous !</h4>
            <div className="icon">
            <a href="https://www.facebook.com/AGNDiagnostics" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
            </a>
            <a href="https://www.linkedin.com/in/agndiagnostics/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
            </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
