import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
  });

  const [captchaValue, setCaptchaValue] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!captchaValue) {
      alert("Veuillez remplir le captcha.");
      return;
    }

    const templateParams = {
      from_name: `${formData.firstName} ${formData.lastName}`,
      to_name: 'contact@agndiagnostics.fr',
      email: formData.email,
      subject: formData.subject,
      message_html: formData.message,
    };

    emailjs.send(
      'service_96d73zf',
      'template_jt4rqfi',
      templateParams,
      'zyHhXThNTDsoMp5ae'
    )
      .then((response) => {
        alert('E-mail envoyé avec succès!');
      })
      .catch((error) => {
        alert('Erreur lors de l\'envoi de l\'e-mail. Veuillez essayer à nouveau ultérieurement.', error);
      });
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <div className="text-center mb-3">
        <strong>"N'hésitez pas à nous contacter pour toute demande de devis ou d'informations."</strong>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="firstName">Prénom :</label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder='Arthur'
            required
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="lastName">Nom :</label>
          <input
            type="text"
            className="form-control"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder='Dubois'
            required
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="email">Email :</label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder='arthurdubois@gmail.com'
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="subject">Sujet :</label>
        <input
          type="text"
          className="form-control"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          placeholder='Demande de diagnostics'
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="message">Message :</label>
        <textarea
          className="form-control"
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder='Ecrivez votre message...'
          required
        ></textarea>
      </div>
      <ReCAPTCHA
        sitekey="6LcDOBApAAAAAHpw-wwTaOwnBhLBELApuNQ0KTZc"
        onChange={handleCaptchaChange}
      />
      <button type="submit" disabled={!captchaValue} className="btn btn-primary">
        Envoyer
      </button>
    </form>
  );
};

export default ContactForm;
