import React from 'react';

const CompanyPresentation = () => {
  return (
    <section className="container mt-5 shadow bg-white company-presentation">
      <div className="row agn-row-center">
        <div className="col-md-6">
          <img
            src="/static/companypres-agn-diagnostics-allier-abrest-vichy.jpg"
            alt="présentation de l'entreprise agn diagnostics située à abrest dans l'allier"
            className="img-fluid"
          />
        </div>
        <div className="col-md-6">
          <h2>Qui sommes-nous ?</h2>
          <p>
          AGN Diagnostics est une société spécialisée dans le diagnostic immobilier et l'expertise énergétique.
          Nous sommes situés au coeur de l'Allier, à Abrest, et intervenons dans un rayon de 50km autour de Vichy.
          </p>
        </div>
      </div>
    </section>
  );
};

export default CompanyPresentation;