import React from 'react';

const DiagnosticTypes = () => {
  const diagnosticData = [
    {
      type: 'Le diagnostic gaz',
      definition: 'Celui-ci donne un aperçu de la sécurité de votre installation.',
      whenToPerform: 'Votre logement est concerné si votre bien est équipé d\'une installation de gaz de plus de 15 ans.',
      image: '/static/gaz-diagnostics-agn-vichy.jpg',
    },
    {
      type: 'Le diagnostic électricité',
      definition: 'Il decrypte votre installation et met en avant les travaux de sécurité à réaliser.',
      whenToPerform: 'Votre logement est concerné si votre installation a plus de 15 ans.',
      image: '/static/elec-diagnostics-agn-vichy.jpg',
    },
    {
      type: 'Le diagnostic plomb',
      definition: 'Grâce à la collaboration de son appareil à fluorescence X, le diagnostiqueur vous donnera les informations sur la présence de plomb contenu dans les peintures de votre logement.',
      whenToPerform: 'Votre logement est concerné s\'il a été construit avant 1949.',
      image: '/static/plomb-diagnostics-agn-vichy.jpg',
    },
    {
      type: 'Le diagnostic amiante',
      definition: 'Grâce à ses connaissances et parfois avec le concour d\'un laboratoire d\'analyse, le diagnostiqueur recherche certains produits et matériaux contenant de l\'amiante et susceptibles d\'entrainer des risques sanitaires.',
      whenToPerform: 'Votre logement est concerné si son permis de construire est antérieur au 1er juillet 1997.',
      image: '/static/amiante-diagnostics-agn-vichy.jpg',
    },
    {
      type: 'Le DPE (Diagnostic de Performance Énergétique)',
      definition: 'Il renseigne sur la performance énergétique et climatique d\'un logement ou bâtiment (noté de A à G), en évaluant sa consommation d\'énergie et son impact en terme d\'émission de gaz à effet de serre.',
      whenToPerform: 'Votre logement est concerné lors de sa mise en vente ou de sa mise en location.',
      image: '/static/dpe-diagnostics-agn-vichy.jpg',
    },
    {
      type: 'La loi Carrez',
      definition: 'Ce document de mesurage atteste de la surface privative d\'un lot en copropriété.',
      whenToPerform: 'Votre logement est concerné s\'il fait parti d\'une copropriété et doit être mis en vente',
      image: '/static/carrez-diagnostics-agn-vichy.jpg',
    },
    {
      type: 'La loi Boutin',
      definition: 'Ce document de mesurage atteste de la surface habitable de votre logement.\n Rappelons qu\'un logement pour être habitable doit comporter au moins une pièce, avoir une surface de plancher de 9m² minimum, une hauteur de plafond de 2m20 minimum et un volume habitable d\'au moins 20m3. A défaut, il ne respecte pas les critères de décence et ne peut être loué.',
      whenToPerform: 'Votre logement est concerné vous souhaitez le mettre en location.',
      image: '/static/boutin-diagnostics-agn-vichy.jpg',
    },
    {
      type: 'ERP (État des Risques et Pollutions)',
      definition: 'L’ERP informe sur les risques naturels, technologiques et les pollutions du secteur.',
      whenToPerform: 'Votre logement est concerné lors de sa mise en vente ou de sa mise en location.',
      image: '/static/erp-diagnostics-agn-vichy.jpg',
    },
  ];

  return (
      <section className="container diagcontainer mt-5">
        <h2 className="text-center mb-4">Nos prestations</h2>
        <p>
          Nos prestations sont réalisées par des diagnostiqueurs certifiés et assurés, qui vous fourniront des rapports clairs et précis.
          Chacune de nos prestations est réalisée dans le respect des normes en vigueur et des règles de sécurité.
        </p>
        {diagnosticData.map((diagnostic, index) => (
          <div key={index} className={`row mb-1 diags-card ${index % 2 === 0 ? 'card-left' : 'card-right'}`}>
            {index % 2 === 0 ?
              <div className='col-md-4 diag-image-col'>
                <img
                  src={diagnostic.image}
                  alt={diagnostic.type}
                  className="img-fluid diag-image"
                />
              </div>
              : null}
            <div className="col-md-8">
              <div className="diag-info">
                <h5 className="diag-title">{diagnostic.type}</h5>
                <p className="diag-description">{diagnostic.definition}</p>
                <p className="diag-contact">
                  <span className='diag-contact-hover'>{diagnostic.whenToPerform}</span><br />
                </p>
              </div>
            </div>
            {index % 2 !== 0 ?
              <div className='col-md-4 diag-image-col'>
                <img
                  src={diagnostic.image}
                  alt={diagnostic.type}
                  className="img-fluid diag-image"
                />
              </div>
              : null}
              <div className='col-md-4 diag-image-col-phone'>
                <img
                  src={diagnostic.image}
                  alt={diagnostic.type}
                  className="img-fluid diag-image"
                />
              </div>
          </div>
        ))}
      </section>
    );
  };

export default DiagnosticTypes;


