import React from 'react';
import { Carousel } from 'react-bootstrap';

const CarouselHome = () => {
  
  return (
    <Carousel className="mb-5 shadow">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/static/vichy-agn-diagnostics.png"
            alt="agn diagnostics diagnostic vichy"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/static/diagnostic-dpe-agn-diagnostics.png"
            alt="dpe agn diagnostics vichy"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="/static/buyhouse-agn-diagnostics-vendre-immobilier.png"
            alt="diagnotic immobilier agn diagnostics vichy"
          />
        </Carousel.Item>
      </Carousel>
  );
};

export default CarouselHome